import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useContext, useState } from "react";
import { AuthContext } from "../../auth/AuthContext";
import { Switch } from "@mui/material";
import { updateSettings } from "../../user/api";
import { TooltipIcon } from "../../shared/forms/TooltipIcon";

export const SendMailOnTestResult = () => {
  const { authState, setAuthState } = useContext(AuthContext);

  const [sendMailTestResult, setSendMailTestResult] = useState(
    authState.customerSettings?.sendMailTestResult ? true : false
  );

  return (
    <Box className="send-mail-on-test-result">
      <FormControlLabel
        control={<Switch />}
        label="Per Mail über neue Ergebnisse informieren."
        checked={sendMailTestResult}
        onChange={() => {
          setSendMailTestResult((p) => !p);
          updateSettings({ sendMailTestResult: !sendMailTestResult });
          setAuthState((p) => ({
            ...p,
            customerSettings: {
              ...p.customerSettings,
              sendMailTestResult: !sendMailTestResult,
            },
          }));
        }}
      />
      <TooltipIcon
        type="info"
        title="Lassen Sie sich per Mail benachrichtigen, wenn ein neues Testergebnis vorliegt."
        sx={{ position: "relative", top: "6px", left: "-12px" }}
      />
    </Box>
  );
};
