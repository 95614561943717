import Box from "@mui/system/Box";

import { TransactionT } from "../../user/api";
import { CandidateResultsWidget } from "./CandidateResultsWidget";
import { Transactions } from "./Transactions";
import { Typography } from "@mui/material";
import { SendMailOnTestResult } from "./SendMailOnTestResult"; // Ensure this path is correct

interface CustomerDashboardPRopsI {
  transactionList: TransactionT[];
}
export const CustomerDashboard = ({
  transactionList,
}: CustomerDashboardPRopsI) => {
  return (
    <Box>
      <Box>
        <Typography variant="h1" component="h1">
          Die neuesten Testergebnisse
        </Typography>
        <CandidateResultsWidget />
        <SendMailOnTestResult />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant="h1" component="h1">
          Meine Online-Tests
        </Typography>
        <Transactions transactionList={transactionList} />
      </Box>
    </Box>
  );
};
