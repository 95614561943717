import { useContext, useEffect, useRef, useState } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { MailTemplateT, sendTestMail } from "./api";
import { FeedbackContext } from "../feedback/FeedbackContext";
import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { AuthContext } from "../auth/AuthContext";
import { Editor } from "@tinymce/tinymce-react";
import { Box } from "@mui/system";
import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ErrorContext } from "../error/ErrorContext";
import { ConfirmDialog } from "../shared/ConfirmDialog";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import { isEqual } from "lodash";

interface MailTemplateFormPropsI {
  mailTemplate: MailTemplateT;
  onClick: (data: MailTemplateT) => void;
}

const tooltip = (
  <>
    <p>
      Fügen Sie in Ihre Mail Platzhalter ein für die personenbezogene Anrede,
      den Vor-und Nachnamen des Bewerbers bzw. der Bewerberin, den individuellen
      Einladungslink oder das Ablaufdatum des Tests.
    </p>
    <br />
    <p>
      Die Platzhalter werden beim Mailversand automatisch durch die konkreten
      Angaben ersetzt.
    </p>
  </>
);

export const MailTemplateForm = (props: MailTemplateFormPropsI) => {
  const [mailTemplateState, setMailTemplateState] = useState<MailTemplateT>(
    props.mailTemplate
  );

  const { authState } = useContext(AuthContext);
  const editorRef = useRef(null as any);

  const { showProgress, hideProgress, openSnackbar } =
    useContext(FeedbackContext);
  const fields2check = [
    "description",
    "fromName",
    "fromEmail",
    "subject",
    "content",
    "salutationM",
    "salutationF",
    "salutationD",
  ];
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const [dialogSendMailOpen, setDialogSendMailOpen] = useState(false);

  const { setError } = useContext(ErrorContext);
  const [hasError, setHasError] = useState(false);

  const [testMailEmail, setTestMailEmail] = useState(authState.user.email);
  const [testMailError, setTestMailError] = useState(false);

  useEffect(() => {
    setMailTemplateState(props.mailTemplate);
  }, [props.mailTemplate, setMailTemplateState]);

  const [hasChanges, setHasChanges] = useState(false);

  /** in/active submit button */
  useEffect(() => {
    checkErrors();
    setHasChanges(!isEqual(mailTemplateState, props.mailTemplate));
  }, [mailTemplateState, props.mailTemplate, setHasChanges]);

  const checkErrors = () => {
    let oneError = false;

    if (!editorRef.current) return true;

    // setMailTemplateState((p) => ({
    //   ...p,
    //   content: editorRef.current.getContent(),
    // }));

    fields2check.forEach((f) => {
      const error =
        f === "content"
          ? !editorRef.current.getContent()
          : mailTemplateState[f as keyof MailTemplateT]
          ? false
          : true;

      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });
    if (oneError) {
      setHasError(true);
      return true;
    }

    let check = editorRef.current.getContent();
    check = check.replace(/{[\w]+\}/g, "");
    check = check.replace(/##[\w]+##/g, "");

    if (
      check.indexOf("{") >= 0 ||
      check.indexOf("}") >= 0 ||
      check.indexOf("#") >= 0
    ) {
      setErrorState((p) =>
        buildErrorState(p, "content", true, "Fehlerhafter Platzhalter")
      );
      // setHasError(true);
      return true;
    }
    setHasError(false);
    return false;
  };

  const onClick = () => {
    if (checkErrors()) return;
    showProgress();
    props.onClick({
      ...mailTemplateState,
      content: editorRef.current.getContent(),
    });
  };

  const onClickTestMail = () => {
    showProgress();
    sendTestMail(mailTemplateState.id, testMailEmail)
      .then((res) => {
        hideProgress();
        if (res.success) openSnackbar("success", "Test-Mail gesendet");
        else setError("Fehler beim Mail versenden");
      })
      .catch((error) => {
        setError(error);
      });
  };

  const insertPlaceholder = (placeholder: string) => {
    if (authState.role !== "admin")
      editorRef.current.insertContent("##" + placeholder + "## ");
    else editorRef.current.insertContent("{" + placeholder + "} ");
  };

  return (
    <div>
      <StyledFormControl required={true}>
        <TextField
          id="description"
          label="Name der Vorlage"
          value={mailTemplateState.description}
          error={errorState.description?.error}
          helperText={
            errorState.description?.error
              ? errorState.description?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setMailTemplateState({
              ...mailTemplateState,
              description: e.target.value,
            })
          }
          required={true}
        />
      </StyledFormControl>

      <StyledFormControl required={true}>
        <TextField
          id="fromName"
          label="Absender-Name"
          value={mailTemplateState.fromName}
          error={errorState.fromName?.error}
          helperText={
            errorState.fromName?.error
              ? errorState.fromName?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setMailTemplateState({
              ...mailTemplateState,
              fromName: e.target.value,
            })
          }
          required={true}
        />
      </StyledFormControl>

      <StyledFormControl>
        <TextField
          id="fromEmail"
          label="Absender E-Mail"
          value={mailTemplateState.fromEmail}
          error={errorState.fromEmail?.error}
          helperText={
            errorState.fromEmail?.error
              ? errorState.fromEmail?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setMailTemplateState({
              ...mailTemplateState,
              fromEmail: e.target.value,
            })
          }
          {...(authState.role !== "admin" ? { disabled: true } : "")}
        />
      </StyledFormControl>

      <StyledFormControl>
        <TextField
          id="subject"
          label="Betreffzeile"
          value={mailTemplateState.subject}
          error={errorState.subject?.error}
          helperText={
            errorState.subject?.error
              ? errorState.subject?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setMailTemplateState({
              ...mailTemplateState,
              subject: e.target.value,
            })
          }
          required={true}
          inputProps={{
            maxLength: 255,
          }}
        />
      </StyledFormControl>

      <StyledFormControl
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "33%" }}>
          <TextField
            id="salutationM"
            label="Anrede (männlich)"
            value={mailTemplateState.salutationM}
            error={errorState.salutationM?.error}
            helperText={
              errorState.salutationM?.error
                ? errorState.salutationM?.helperText || "Bitte ausfüllen"
                : ""
            }
            onChange={(e) =>
              setMailTemplateState({
                ...mailTemplateState,
                salutationM: e.target.value,
              })
            }
            sx={{ width: "100%", pr: 1 }}
            required={true}
          />
        </Box>
        <Box sx={{ width: "33%" }}>
          <TextField
            id="salutationF"
            label="Anrede (weiblich)"
            value={mailTemplateState.salutationF}
            error={errorState.salutationF?.error}
            helperText={
              errorState.salutationF?.error
                ? errorState.salutationF?.helperText || "Bitte ausfüllen"
                : ""
            }
            onChange={(e) =>
              setMailTemplateState({
                ...mailTemplateState,
                salutationF: e.target.value,
              })
            }
            sx={{ width: "100%", pr: 1 }}
            required={true}
          />
        </Box>
        <Box sx={{ width: "33%" }}>
          <TextField
            id="salutationD"
            label="Anrede (divers / unbekannt)"
            value={mailTemplateState.salutationD}
            error={errorState.salutationD?.error}
            helperText={
              errorState.salutationD?.error
                ? errorState.salutationD?.helperText || "Bitte ausfüllen"
                : ""
            }
            onChange={(e) =>
              setMailTemplateState({
                ...mailTemplateState,
                salutationD: e.target.value,
              })
            }
            sx={{ width: "100%" }}
            required={true}
          />
        </Box>
      </StyledFormControl>
      <StyledFormControl
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: "15px",
        }}
      >
        <Box sx={{ width: "32%" }}>
          <InputLabel htmlFor="placeholder">Platzhalter einfügen</InputLabel>

          {authState.role !== "admin" ? (
            <Select
              id="placeholder"
              label="Platzhalter einfügen"
              onChange={(e: SelectChangeEvent<string>) =>
                insertPlaceholder(e.target.value)
              }
              value=""
              sx={{ width: "100%", pr: 1 }}
            >
              <MenuItem value="ANREDE">##ANREDE##</MenuItem>
              <MenuItem value="VORNAME">##VORNAME##</MenuItem>
              <MenuItem value="NACHNAME">##NACHNAME##</MenuItem>
              <MenuItem value="EINLADUNGSLINK">##EINLADUNGSLINK##</MenuItem>
              <MenuItem value="ABLAUFDATUM">##ABLAUFDATUM##</MenuItem>
            </Select>
          ) : (
            <Select
              id="placeholder"
              label="Platzhalter einfügen"
              onChange={(e: SelectChangeEvent<string>) =>
                insertPlaceholder(e.target.value)
              }
              value=""
              sx={{ width: "100%", pr: 1 }}
            >
              <MenuItem value="salutation">&#123;salutation&#125;</MenuItem>
              <MenuItem value="firstname">&#123;firstname&#125;</MenuItem>
              <MenuItem value="lastname">&#123;lastname&#125;</MenuItem>
              <MenuItem value="candidate_firstname">
                &#123;candidate_firstname&#125;
              </MenuItem>
              <MenuItem value="candidate_lastname">
                &#123;candidate_lastname&#125;
              </MenuItem>
              <MenuItem value="test_name">&#123;test_name&#125;</MenuItem>
              <MenuItem value="passwordlink">
                &#123;passwordlink&#125; (Passwort vergessen)
              </MenuItem>
              <MenuItem value="testaccountpasswordlink">
                &#123;testaccountpasswordlink&#125; (Test-Account)
              </MenuItem>
              <MenuItem value="ANREDE">
                ##ANREDE## (nur für Kandidaten)
              </MenuItem>
              <MenuItem value="VORNAME">
                ##VORNAME## (nur für Kandidaten)
              </MenuItem>
              <MenuItem value="NACHNAME">
                ##NACHNAME## (nur für Kandidaten)
              </MenuItem>
              <MenuItem value="EINLADUNGSLINK">
                ##EINLADUNGSLINK## (nur für Kandidaten)
              </MenuItem>
              <MenuItem value="ABLAUFDATUM">
                ##ABLAUFDATUM## (nur für Kandidaten)
              </MenuItem>
            </Select>
          )}
        </Box>
        <Box
          sx={{
            width: "67%",
            pl: 1,
            pt: "1px",
            fontSize: "2rem",
          }}
        >
          <Typography variant="h1" sx={{ pt: "10px" }}>
            <TooltipIcon
              type="info"
              title={tooltip}
              sx={{ position: "relative", top: "3px" }}
            />
          </Typography>
        </Box>
      </StyledFormControl>

      <StyledFormControl>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box
              sx={
                errorState.content?.error
                  ? {
                      border: "1px solid red",
                    }
                  : {}
              }
            >
              <Editor
                apiKey="ot8ti4mrmg21ckfbqldpnaa759khpd8ntv385nw1s7mxfuia"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={mailTemplateState.content}
                init={{
                  height: 500,
                  language: "de",
                  menubar: false,
                  // word_wrap: true,
                  // plugins: "wordwrap",
                  plugins: ["lists", "link"],
                  paste_as_text: true,
                  resize: true,
                  //   " autolink lists link image charmap print preview anchor",
                  //   "searchreplace visualblocks code fullscreen",
                  //   "insertdatetime media table paste code help wordcount",
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic underline | alignleft aligncenter " +
                    "alignright alignjustify | bullist outdent indent | " +
                    (authState.user.role === "admin" ? "link " : "") +
                    "removeformat",
                  content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; moverflow: hidden;  white-space: pre-wrap;   word-wrap: break-word; }                   `,
                  autoformat_remove: true,
                  text_patterns: false,
                }}
                onEditorChange={(content) => {
                  //setMailTemplateState({ ...mailTemplateState, content })
                  checkErrors();
                  setHasChanges(content !== props.mailTemplate.content);
                }}
              />
            </Box>

            {errorState.content?.error && (
              <Box sx={{ pl: 2, pt: "3px" }}>
                <Typography color="error" variant="caption">
                  {errorState.content?.helperText || "Bitte ausfüllen"}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </StyledFormControl>

      {hasError && (
        <Box sx={{ color: "red", mt: 1, ml: "12px", mb: 1 }}>
          Bitte füllen Sie alle mit * markierten Pflichtfelder.
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        sx={{ ml: 1, width: "250px" }}
        disabled={!hasChanges}
      >
        Speichern
      </Button>
      {mailTemplateState.id !== -1 && (
        <Box sx={{ ml: 1 }}>
          <Typography variant="h2" component="h2" sx={{ mt: 3, mb: 2 }}>
            Testmail verschicken
          </Typography>
          <Typography variant="body1" component="p">
            Verschicken Sie die E-Mail zur Prüfung z. B. an sich selbst.
          </Typography>
          <StyledFormControl sx={{ ml: 0 }}>
            <TextField
              id="email"
              label="E-Mail"
              value={testMailEmail}
              error={testMailError}
              helperText={testMailError ? "Bitte ausfüllen" : ""}
              onChange={(e) => setTestMailEmail(e.target.value)}
            />
          </StyledFormControl>

          <Button
            sx={{ width: "250px" }}
            variant="contained"
            color="primary"
            onClick={(e) => {
              if (!testMailEmail) {
                setTestMailError(true);
                return;
              }
              setDialogSendMailOpen(true);
            }}
          >
            Testmail verschicken
          </Button>
        </Box>
      )}

      <ConfirmDialog
        open={dialogSendMailOpen}
        handleYes={() => {
          onClickTestMail();
          setDialogSendMailOpen(false);
        }}
        handleNo={() => setDialogSendMailOpen(false)}
        title="Mail verschicken"
        content="Soll eine Test-Mail versendet werden?"
      />
    </div>
  );
};
