import { useContext, useEffect, useState } from "react";

import {
  ProfessionContext,
  ProfessionProvider,
} from "../../profession/ProfessionContext";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { datetime_hr } from "../../shared/helper/datetime";
import { CandidateContext } from "../../candidate/CandidateContext";
import { CandidateT, updateCandidateBulk } from "../../candidate/api";
import { EnhancedTableHead, HeadCellT } from "../../shared/table/EnhancedTable";
import { usePagination } from "../../shared/hooks/usePagination";
import { Grid, Modal, TablePagination } from "@mui/material";
import React from "react";
import { EditableCell } from "../../shared/list/InsertCell";
import SaveChanges from "../../shared/list/SaveChanges";
import { ErrorContext } from "../../error/ErrorContext";
import { FeedbackContext } from "../../feedback/FeedbackContext";
import { debouncedSetUpdatedState } from "../../shared/helper/state";
import { ResultsLoader } from "../../candidate/results/ResultsLoader";
import { CloseModalButton } from "../../shared/modal/CloseModalButton";
import { Tooltip } from "../../shared/forms/Tooltip";
import PrintIcon from "@mui/icons-material/Print";
import { CandidateResultsModal } from "../../candidate/CandidateResultsModal";

interface CandidateResultsWidgetPropsI {
  professionId?: number;
}

interface FilteredCandidateT extends Partial<CandidateT> {
  data?: CandidateT;
  firstname: string;
  testedAt?: Date;
  createdAt: Date;
  resultPercent?: string;
  concentrationPercent?: string;
  resultsMain?: any[];
  targetValue: number;
  hasTarget: boolean;
  hasConcentration: boolean;
  hasOnlyConcentration: boolean;
}

const sortFunc = (a: FilteredCandidateT, b: FilteredCandidateT) => {
  return a.testedAt && b.testedAt
    ? b.testedAt.getTime() - a.testedAt.getTime()
    : -1;
};

const headCells: HeadCellT<FilteredCandidateT>[] = [
  {
    id: "lastname",
    disablePadding: false,
    label: "Name",
  },
  {
    id: "firstname",
    disablePadding: true,
    label: "Vorname",
  },
  { id: "professionId", disablePadding: true, label: "Test" },
  { id: "testedAt", disablePadding: true, label: "Testdatum" },
  { id: "resultPercent", disablePadding: true, label: "Ergebnis" },
  {
    id: "targetValue",
    disablePadding: true,
    label: "Sollprofil",
    tooltip: (
      <>
        Das Sollprofil entspricht Ihren Angaben in der Übersicht „Meine Tests“
        auf der Startseite. Es drückt aus, welchen Prozentwert der
        Maximalpunktzahl Sie insgesamt und in den jeweiligen Testbereichen zum
        Bestehen voraussetzen. Im Allgemeinen sollte das Ergebnis nicht unter 50
        % liegen.
        <br />
        <br />
        Aufgrund der empirischen Erfahrungswerte ergeben sich zur Einschätzung
        der Ergebnisse folgende Orientierungsgrößen:
        <br />
        <ul>
          <li>
            86–100 %: sehr gutes Ergebnis, voll befriedigende Kompetenzen in
            allen Testkategorien, entspricht insgesamt einem sehr hohen
            Leistungsniveau
          </li>
          <li>
            71–85 %: gutes Ergebnis, in allen Testkategorien mindestens erfüllte
            Grundanforderungen, entspricht insgesamt einem hohen Leistungsniveau
          </li>
          <li>
            61–70 %: zufriedenstellendes Ergebnis, in den wichtigsten
            Testkategorien erfüllte Grundanforderungen, entspricht insgesamt
            einem überdurchschnittlichen Leistungsniveau
          </li>
          <li>
            50–60 %: akzeptables Ergebnis, weitgehend erfüllte
            Grundanforderungen, entspricht insgesamt einem ausreichenden
            Leistungsniveau
          </li>
        </ul>
      </>
    ),
  },
  {
    id: "concentrationPercent",
    disablePadding: true,
    label: "Konzentrationstest",
    tooltip: (
      <>
        Bei Prüfungen mit mehreren Teilgebieten wird der Konzentrationstest
        gesondert ausgewertet. Die hier erreichten Punktzahlen fließen nicht in
        das Gesamtergebnis ein. Vergleichen Sie die Teilnehmerleistungen direkt
        miteinander.
      </>
    ),
  },
  { id: "comment", disablePadding: true, label: "Bemerkungen" },
];

export const CandidateResultsWidget = ({
  professionId = 0,
}: CandidateResultsWidgetPropsI) => {
  const { professionList } = useContext(ProfessionContext);
  const {
    candidateList,
    setCandidateList,
    changedCandidateList,
    setChangedCandidateList,
  } = useContext(CandidateContext);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination("candidateLogs", { forceRowsPerPage: 5 });

  const [showSave, setShowSave] = useState(false);
  const [showResults, setShowResults] = useState(0);

  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  /** ************************************************************************
   *
   *
   */
  const saveCandidates = () => {
    showProgress();

    updateCandidateBulk(changedCandidateList)
      .then((result) => {
        if (result.success) {
          openSnackbar("success", "Daten gespeichert");
          setCandidateList((prevState) =>
            prevState.map((oldCat) => {
              const replCat = changedCandidateList.find(
                (e) => e.id === oldCat.id
              );

              if (replCat) {
                return {
                  ...oldCat,
                  ...replCat,
                };
              }
              return oldCat;
            })
          );
          setChangedCandidateList([]);
          setShowSave(false);
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const mapCandidates = (c: CandidateT): FilteredCandidateT => {
    const logs = c.logs
      ? c.logs.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
      : undefined;

    const profession = professionList.find((p) => p.id === c.professionId);

    const resultPercent =
      c.results && c.results.sumPoints > 0
        ? ((c.results.sumPointsCorrect / c.results.sumPoints) * 100).toFixed(0)
        : undefined;

    let hasTarget = Boolean(
      c.results?.professionTarget &&
        c.results?.professionTarget < parseFloat(resultPercent || "0")
    );
    if (hasTarget) {
      c.results?.mainCategories.forEach((m) => {
        if (m.concentration) return;
        const percent =
          m.sumPoints > 0 ? (m.sumPointsCorrect / m.sumPoints) * 100 : 0;

        if (m.mainCategoryTarget > percent) {
          hasTarget = false;
        }
      });
    }

    return {
      data: c,
      id: c.id,
      firstname: c.firstname,
      lastname: c.lastname,
      professionId: c.professionId,
      createdAt: c.createdAt,
      testedAt:
        c.results &&
        c.results.mainCategories &&
        c.results.mainCategories.length > 0 &&
        logs &&
        logs.length > 0
          ? logs[0].createdAt
          : undefined,
      resultPercent: resultPercent,

      concentrationPercent:
        c.results && c.results.concentrationPoints > 0
          ? (
              (c.results.concentrationPointsCorrect /
                c.results.concentrationPoints) *
              100
            ).toFixed(0)
          : undefined,
      resultsMain: c.results
        ? c.results.mainCategories.map((m) => ({
            name: m.mainCategoryName,
            percent: (m.sumPoints > 0
              ? (m.sumPointsCorrect / m.sumPoints) * 100
              : 0
            ).toFixed(4),
          }))
        : undefined,
      targetValue: profession?.targetValue || 0,
      hasTarget: hasTarget,
      hasConcentration:
        c.results && c.results.mainCategories.find((m) => m.concentration)
          ? true
          : false,
      hasOnlyConcentration:
        c.results &&
        c.results.mainCategories.find(
          (m) => !("concentration" in m && m.concentration)
        )
          ? false
          : true,
    };
  };

  let filteredCandidateList: FilteredCandidateT[] = professionId
    ? candidateList
        .filter((c) => c.professionId === professionId)
        .map(mapCandidates)
        .sort(sortFunc)
    : candidateList
        .map(mapCandidates)
        .filter((c) => c && c.testedAt && c.professionId)
        .sort(sortFunc);

  const hasConcentration = filteredCandidateList.find(
    (c) => c.hasConcentration
  );

  if (filteredCandidateList.length === 0)
    return (
      <Box sx={{ minHeight: 90 }}>Es liegen noch keine Ergebnisse vor.</Box>
    );

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>{showSave && <SaveChanges onClick={saveCandidates} />}</Grid>
      </Grid>
      <TableContainer>
        <Table
          sx={{ width: "100%" }}
          size="small"
          className="enhanced-table results-widget"
        >
          <EnhancedTableHead
            headCells={headCells
              .filter((c) => !professionId || c.id !== "professionId")
              .filter(
                (c) => hasConcentration || c.id !== "concentrationPercent"
              )}
            rowCount={filteredCandidateList.length}
          />
          <TableBody className="enhanced-table-body">
            {filteredCandidateList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((candidate) => {
                const pro = professionList.find(
                  (p) => candidate.professionId === p.id
                );

                return (
                  <React.Fragment key={candidate.id}>
                    <TableRow
                      sx={{
                        border: 0,
                        td:
                          professionId > 0 && candidate.resultsMain
                            ? { borderBottom: 0 }
                            : {},
                      }}
                      data-testid={"row-cid-" + candidate.id}
                    >
                      <TableCell component="td" scope="row">
                        {candidate.lastname}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {candidate.firstname}
                      </TableCell>
                      {!professionId && pro && (
                        <TableCell component="td" scope="row">
                          {pro.name} {pro.testId}
                        </TableCell>
                      )}
                      <TableCell component="td" scope="row">
                        {candidate.testedAt && datetime_hr(candidate.testedAt)}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <Tooltip title="Auswertung anzeigen">
                          {candidate.hasOnlyConcentration ? (
                            <>&ndash;</>
                          ) : (
                            <a
                              onClick={() => setShowResults(candidate.id || 0)}
                              data-testid={"result-cid-" + candidate.id}
                            >
                              {candidate.resultPercent
                                ? candidate.resultPercent + " %"
                                : "0 %"}
                            </a>
                          )}
                        </Tooltip>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <Tooltip title="Auswertung anzeigen">
                          <a onClick={() => setShowResults(candidate.id || 0)}>
                            {candidate.hasTarget
                              ? "erreicht"
                              : "nicht erreicht"}
                          </a>
                        </Tooltip>
                      </TableCell>
                      {hasConcentration && (
                        <TableCell>
                          <Tooltip title="Auswertung anzeigen">
                            {!candidate.hasConcentration ? (
                              <>&ndash;</>
                            ) : (
                              <a
                                onClick={() =>
                                  setShowResults(candidate.id || 0)
                                }
                              >
                                {candidate.concentrationPercent
                                  ? candidate.concentrationPercent + " %"
                                  : "0 %"}
                              </a>
                            )}
                          </Tooltip>
                        </TableCell>
                      )}
                      {candidate && candidate.data && (
                        <EditableComent
                          candidate={candidate.data}
                          setShowSave={setShowSave}
                          setChangedCandidateList={setChangedCandidateList}
                        />
                      )}
                    </TableRow>
                    {professionId > 0 && candidate.resultsMain && (
                      <TableRow sx={{ borderTop: 0, borderTopColor: "green" }}>
                        <TableCell colSpan={8}>
                          <Box sx={{ mb: 2 }}>
                            {candidate.resultsMain.map((m) => (
                              <React.Fragment key={m.id}>
                                {m.name} : {m.percent}%{" "}
                              </React.Fragment>
                            ))}
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "Alle", value: -1 }]}
        component="div"
        count={filteredCandidateList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
        onRowsPerPageChange={(event) =>
          handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)
        }
        labelRowsPerPage="Einträge anzeigen:"
        // className="hide-pagination-next-last"
      />

      {showResults > 0 && (
        <CandidateResultsModal
          showResults={showResults}
          setShowResults={setShowResults}
        />
      )}
    </>
  );
};

interface EditableComentPropsI {
  candidate: CandidateT;
  setShowSave: (b: boolean) => void;
  setChangedCandidateList: (c: CandidateT[]) => void;
}
const EditableComent = ({
  candidate,
  setShowSave,
  setChangedCandidateList,
}: EditableComentPropsI) => {
  const [candidateState, setCandidateState] = useState(candidate);

  useEffect(() => {
    if (candidateState !== candidate) {
      setShowSave(true);
      debouncedSetUpdatedState(candidateState, setChangedCandidateList);
    }
  }, [candidateState, setChangedCandidateList, setShowSave]);

  return (
    <EditableCell
      id="comment"
      label="Bemerkungen"
      stateObject={candidateState}
      setStateObject={setCandidateState}
    />
  );
};
