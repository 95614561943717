import { FileT } from "../file/api";
import { CallApiResultT, post, get, put } from "../shared/lib/api";

export interface FetchTokenI {
  email: string;
  password: string;
}

export interface FetchTokenAutoLoginI {
  uuid: string;
}

export type TokenDataT = {
  user: any;
  token: string;
  settings?: any;
  alreadyStarted?: boolean;
  json?: string;
  customerSettings?: any;
};

export interface SendNewPasswordI {
  email: string;
}

export const fetchToken = async ({
  email,
  password,
}: FetchTokenI): Promise<CallApiResultT<TokenDataT>> => {
  const res = await post("/login", {
    email: email,
    password: password,
  });

  if (!res.data || !(res.data as TokenDataT).token)
    throw new Error("[fetchToken] did not return token");

  return {
    success: true,
    data: res.data as TokenDataT,
    status: res.status,
    error: "",
  };
};

export const checkPassword = async ({
  email,
  password,
}: FetchTokenI): Promise<CallApiResultT<string>> => {
  const res = await get<string>("/password-check", {
    email: email,
    password: password,
  });

  return res;
};

export const fetchTokenAutoLogin = async ({
  uuid,
}: FetchTokenAutoLoginI): Promise<CallApiResultT<TokenDataT>> => {
  console.log("fetchTokenAutoLogin", uuid);
  const res = await get("/auto-login/" + uuid);

  console.log("%capi.ts line:63 res.data", "color: #007acc;", res);
  if (res.success === false && res.error) {
    return {
      success: false,
      status: res.status,
      error: res.error,
    };
  }
  if (!res.data || !(res.data as TokenDataT).token)
    throw new Error("[fetchTokenAutoLogin] did not return token");
  console.log("res.data von autologin:", res.data);
  return {
    success: true,
    data: res.data as TokenDataT,
    status: res.status,
    error: "",
  };
};

interface CheckResetPasswortTokenI {
  token: string;
}
type CheckResetPasswortTokenR = { role: string };
export const checkResetPasswortToken = async ({
  token,
}: CheckResetPasswortTokenI): Promise<
  CallApiResultT<CheckResetPasswortTokenR>
> => {
  const res = await get<CheckResetPasswortTokenR>("/new-password-check", {
    token: token,
  });

  return res;
};
interface SetNewPasswordTokenI {
  token: string;
  password: string;
}
export const setNewPasswordToken = async ({
  token,
  password,
}: SetNewPasswordTokenI): Promise<CallApiResultT<number>> => {
  const res = await post<number>("/new-password-change", {
    token: token,
    password: password,
  });

  return res;
};
export const sendNewPassword = async ({
  email,
}: SendNewPasswordI): Promise<CallApiResultT<number>> => {
  const res = await post<number>("/new-password-send", { email: email });

  return res;
};

export const logout = async (): Promise<CallApiResultT<number>> => {
  console.log("api call logout");
  const res = await post("/logout", {});

  if (res.status === 200 || res.status === 401)
    return {
      success: true,
      status: res.status,
      error: "",
    };

  return {
    success: false,
    status: res.status,
    error: "logout error 65",
  };
};

export type UserSettingsT = {
  [key: string]: string;
};

export type CustomerSettingsT = {
  disability?: boolean;
  sendMailTestResult?: boolean;
};

type GetUserRoleResultT = {
  role: string;
  user?: any;
  settings?: UserSettingsT;
  customerSettings?: CustomerSettingsT;
  message?: any;
};

export const getUserRole = async (): Promise<
  CallApiResultT<GetUserRoleResultT>
> => {
  const res = await get<GetUserRoleResultT>("/user-role");

  return res;
};

/** ************************************************************************
 *
 */
export const saveUserSettings = async (
  key: string,
  settings: string
): Promise<CallApiResultT<number>> => {
  const res = await post<number>("/settings", {
    key,
    settings,
  });

  return res;
};

/** ************************************************************************
 *
 */
export const getUserSetting = async (
  key: string
): Promise<CallApiResultT<any>> => {
  const res = await get<any>("/settings/" + key);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateLogo = async (
  file: FileT
): Promise<CallApiResultT<number>> => {
  const res = await put<number>("/settings-logo", { fileId: file.id });

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const fetchLogo = async (): Promise<CallApiResultT<FileT>> => {
  const res = await get<FileT>("/settings-logo");

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const logMisc = async (data: any): Promise<CallApiResultT<void>> => {
  console.log("%capi.ts line:202 logMisc, data", "color: #007acc;", data);
  return post<void>("/log", data);
};
